import { functions } from '../initFirebase';
import { toggleAttribute } from '../utils';
import {
  TITLE_CONTACT_ID,
  TITLE_CONFIRM_ID,
  TITLE_THANK_ID,
  DESCRIPTION_ID,
  SENT_MAIL_ID,
  THANK_MESSAGE_ID,
  FORM_ID,
  ENV_ID,
  BUTTON_ID,
  BACK_LINK_ID,
  MAIL_ID,
  PAGE,
  viewConfig,
} from './constants';

/**
 * @classdesc フォームの入力バリデーションと表示変更の管理を行うクラス
 * @param {HTMLInputElement|HTMLTextAreaElement} element - フォームアイテムの element 要素
 */
class FormView {
  constructor(formItems, $button) {
    this.formItems = formItems;
    this.$button = $button;
    this.state = {
      page: PAGE.CONTACT,
      mailValue: '',
      isMailMatched: false
    }

    this.isAllValid = this.isAllValid.bind(this)
    this.setIsMailMatched = this.setIsMailMatched.bind(this);
    this.setPage = this.setPage.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setAction = this.setAction.bind(this);
  }

  setPage(newPage) {
    this.state.page = newPage;
  }

  setMailValue(value) {
    this.state.mailValue = value;
  }

  setIsMailMatched(isMatched) {
    this.state.isMailMatched = isMatched;
  }

  isAllValid() {
    return Object.keys(this.formItems)
      .every(id => this.formItems[id].validity.valid) && this.state.isMailMatched;
  }

  changeView() {
    const current = Object.keys(viewConfig).filter((key) => viewConfig[key].pathname === location.pathname)[0];

    // 不明 URL の場合は CONTACT ページを表示
    this.setPage(current || PAGE.CONTACT);
    updateView(viewConfig[this.state.page], this.formItems, this.state.mailValue);
    this.$button.disabled = !this.isAllValid();
  }

  setAction() {
    this.setMailValue(this.formItems[MAIL_ID].validity.value);
    submitForm(this.state.page, this.setPage, this.formItems);
    updateView(viewConfig[this.state.page], this.formItems, this.state.mailValue);
  }

  createCheckAllValidation(verifyItem) {
    const { $button, isAllValid, setIsMailMatched } = this;
    if (verifyItem) {
      return (e) => {
        const { value } = e.currentTarget;
        const verifyItemValue = verifyItem.validity.value;

        setIsMailMatched(!verifyItemValue || value === verifyItemValue);
        $button.disabled = !isAllValid();
      };
    }

    return () => {
      $button.disabled = !isAllValid();
    };
  }

  createChangeStyle(formItem, verifyItem) {
    const { state } = this;
    if (verifyItem) {
      return () => {
        toggleAttribute(
          formItem.$styleTarget,
          'aria-invalid',
          !(formItem.validity.valid && state.isMailMatched)
        );
        toggleAttribute(
          verifyItem.$styleTarget,
          'aria-invalid',
          !(verifyItem.validity.valid && state.isMailMatched)
        );
      };
    }

    return () => {
      toggleAttribute(formItem.$styleTarget, 'aria-invalid', !formItem.validity.valid);
    };
  }
}

function updateView(view, formItems, mailValue) {
  const $titleContact = document.getElementById(TITLE_CONTACT_ID);
  const $titleConfirm = document.getElementById(TITLE_CONFIRM_ID);
  const $titleThank = document.getElementById(TITLE_THANK_ID);
  const $description = document.getElementById(DESCRIPTION_ID);
  const $sentMailView = document.getElementById(SENT_MAIL_ID);
  const $thankMessage = document.getElementById(THANK_MESSAGE_ID);
  const $form = document.getElementById(FORM_ID);
  const $button = document.getElementById(BUTTON_ID);
  const $backLink = document.getElementById(BACK_LINK_ID);

  $button.textContent = view.buttonText;
  $sentMailView.textContent = mailValue;

  toggleAttribute($titleContact, 'aria-hidden', view.readonly);
  toggleAttribute($titleContact, 'data-loaded', !view.readonly);
  toggleAttribute($titleConfirm, 'aria-hidden', !view.readonly || view.isFormCompleted);
  toggleAttribute($titleConfirm, 'data-loaded', !(!view.readonly || view.isFormCompleted));
  toggleAttribute($titleThank, 'aria-hidden', !view.isFormCompleted);
  toggleAttribute($titleThank, 'data-loaded', view.isFormCompleted);

  toggleAttribute($form, 'aria-readonly', view.readonly);
  toggleAttribute($backLink, 'aria-hidden', !view.readonly);
  toggleAttribute($description, 'aria-hidden', view.readonly);
  toggleAttribute($form, 'aria-hidden', view.isFormCompleted);
  toggleAttribute($thankMessage, 'data-sended', view.isFormCompleted);

  Object.keys(formItems).forEach(key => {
    const item = formItems[key];
    toggleAttribute(item.validity.element, 'readonly', view.readonly);
    toggleAttribute(item.$styleTarget, 'aria-readonly', view.readonly);

    if (!view.readonly) return;

    const $valueDisplay = item.$styleTarget.querySelector('p');
    if (!$valueDisplay) return;

    $valueDisplay.textContent = item.validity.value;
  });

  $button.blur();
  document.body.scrollIntoView(true);
}

function submitForm(page, setPage, formItems) {
  const $inputEnv = document.getElementById(ENV_ID);
  const shouldBeMailSent = page === PAGE.CONFIRM;
  const nextPage = viewConfig[page].nextPage;

  // CONFIRM 時のみメール送信
  if (shouldBeMailSent) {
    const formItemIds = Object.keys(formItems);
    const userInputData = {
      aima_env: $inputEnv.value,
    };
    formItemIds.forEach(id => {
      userInputData[id.replace('js-contact_', '')] = formItems[id].validity.value;
    });

    sendToFunctions(userInputData);

    // 二重送信を防ぐためにフォーム内容をリセット&新規用バリデーションリセット
    formItemIds.forEach(id => formItems[id].validity.reset());
  }

  setPage(nextPage);
  changeUrl(viewConfig[nextPage].pathname, shouldBeMailSent);
}

function changeUrl(url, shouldBeReplaced) {
  if (shouldBeReplaced) {
    window.history.replaceState('', '', url);
  } else {
    window.history.pushState('', '', url);
  }
}

function sendToFunctions(data) {
  const callSendMail = functions.httpsCallable('sendMail')
  callSendMail(data)
  .catch((e) => {
    console.warn(e);
  })
  console.debug(data)
}

export default FormView;
