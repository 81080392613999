import 'intersection-observer';

function initTitleAnimation(media) {
  const $root = document.querySelector('[data-scroll-container]');
  const $targets = document.querySelectorAll('[data-scroll]');
  const $scaleTarget = document.querySelector('[data-scroll-scale');
  const displayWidth = window.innerWidth;
  const heroWidth = displayWidth - 60;
  const heroFullScale = displayWidth / heroWidth;
  let startTop = $scaleTarget ? $scaleTarget.getBoundingClientRect().height : 0;

  if (!$root || !$targets.length) return;

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: buildThresholdList()
  }

  const observer = new IntersectionObserver(setDataLoaded, options);

  $targets.forEach(target => {
    observer.observe(target);
  });

  function setDataLoaded(entries, observer) {
    entries.forEach(entry => {
      const ratio = entry.intersectionRatio;
      if (ratio < .001) return;

      entry.target.setAttribute('data-loaded', 'true');

      if (media !== 'sp' || !entry.target.dataset.bg) {
        observer.unobserve(entry.target);
        return;
      }

      const rect = entry.intersectionRect;
      if (!startTop) startTop = rect.top;

      const opacity = rect.top < 0 ? 1 : Math.min(2 * (1 - rect.top / startTop), 1);
      const scale = Math.min(Math.max(1 + opacity * .2, 1), heroFullScale);
      entry.target.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
      $scaleTarget.style.transform = `scale(${scale})`;
    });
  };
}

function buildThresholdList() {
  const thresholds = [];
  const numSteps = 100;

  for (let i = 1; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

export default initTitleAnimation;
