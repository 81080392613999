export const TITLE_CONTACT_ID = 'js-title--contact';
export const TITLE_CONFIRM_ID = 'js-title--confirm';
export const TITLE_THANK_ID = 'js-title--thank';
export const DESCRIPTION_ID = 'js-contact_description';
export const FORM_ID = 'js-contact_form';
export const MAIL_ID = 'js-contact_mail';
export const ENV_ID = 'js-contact_env';
export const BUTTON_ID = 'js-contact_submit';
export const BACK_LINK_ID = 'js-contact_back';
export const SENT_MAIL_ID = 'js-contact_sent_mail';
export const THANK_MESSAGE_ID = 'js-contact_thank';

export const PAGE = {
  CONTACT: 'contact',
  CONFIRM: 'confirm',
  THANK: 'thank',
};

export const viewConfig = {
  [PAGE.CONTACT]: {
    pathname: '/contact/',
    title: 'CONTACT',
    buttonText: '送信内容を確認する',
    isFormCompleted: false,
    readonly: false,
    nextPage: PAGE.CONFIRM,
  },
  [PAGE.CONFIRM]: {
    pathname: '/contact/confirm/',
    title: 'CONFIRM',
    buttonText: '送信する',
    isFormCompleted: false,
    readonly: true,
    nextPage: PAGE.THANK,
  },
  [PAGE.THANK]: {
    pathname: '/contact/thankyou/',
    title: 'THANK YOU',
    buttonText: '',
    isFormCompleted: true,
    readonly: true,
    nextPage: PAGE.THANK,
  }
};
