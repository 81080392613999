function initSplash() {
  const $splashContainer = document.getElementById('js-splash');
  const $aboutContainer = document.getElementById('js-about');
  const $whoWeAreTitle = document.getElementById('js-title-whoweare');

  $splashContainer.setAttribute('data-loaded', 'true');

  setTimeout(() => {
    document.body.scrollIntoView();
  }, 9500);

  setTimeout(() => {
    $splashContainer.setAttribute('aria-hidden', 'true');
    $aboutContainer.setAttribute('data-loaded', 'true');
    $whoWeAreTitle.setAttribute('data-loaded', 'true');
  }, 10500);
}

export function hideSplash() {
  const $splashContainer = document.getElementById('js-splash');
  const $aboutContainer = document.getElementById('js-about');
  const $whoWeAreTitle = document.getElementById('js-title-whoweare');

  $splashContainer.setAttribute('aria-hidden', 'true');
  $aboutContainer.setAttribute('data-loaded', 'true');
  $whoWeAreTitle.setAttribute('data-loaded', 'true');
}

export default initSplash;
