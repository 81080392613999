import { toggleAttribute } from './utils';

function initMenuEvent() {
  const $headerLogo = document.getElementById('js-header');
  const $navContainer = document.getElementById('js-nav');
  const $menuButton = document.getElementById('js-nav_button');
  const $menuContent = document.getElementById('js-nav_menu');
  const $buttonLabel = document.getElementById('js-nav_button_label');

  const state = {
    isOpen: !!($navContainer.getAttribute('aria-expanded')),
  }

  function openCloseMenu() {
    state.isOpen = !state.isOpen;

    toggleAttribute($headerLogo, 'data-hidden', !state.isOpen);
    toggleAttribute($navContainer, 'aria-expanded', state.isOpen);
    toggleAttribute($menuContent, 'aria-hidden', !state.isOpen);
    setTimeout(() => {
      $buttonLabel.textContent = state.isOpen ? 'CLOSE' : 'MENU';
    }, 300);
  }

  if ($menuButton) {
    $menuButton.addEventListener('click', openCloseMenu)
  }

  if (location.pathname !== '/') return;

  // セクションへのページ内リンクは押下時にメニューを閉じる
  const $sectionLinks = $navContainer.querySelectorAll('[data-section-link]');
  if (!$sectionLinks.length) return;

  $sectionLinks.forEach(elm => {
    elm.addEventListener('click', openCloseMenu);
  });
}

export default initMenuEvent;
