// import { functions } from '../initFirebase';
import FormItemValidator from './FormItemValidator';
import FormView from './FormView';
import { FORM_ID, BUTTON_ID, BACK_LINK_ID } from './constants';

function initFormEvent() {
  const $form = document.getElementById(FORM_ID);
  if (!$form) return;

  const $button = document.getElementById(BUTTON_ID);
  const $backLink = document.getElementById(BACK_LINK_ID);
  const $formElements = $form.querySelectorAll('input:not([type="hidden"]), textarea');
  const formItems = Array.from($formElements, elm => ({
    [elm.id]: {
      $styleTarget: document.querySelector(`[aria-labelledby="${elm.id}"]`),
      validity: new FormItemValidator(elm),
    }
  }))
  .reduce((l, r) => Object.assign(l, r), {});

  const formView = new FormView(formItems, $button);

  // ブラウザの履歴ボタン押下時に表示の変更を行うイベント
  window.addEventListener('popstate', formView.changeView);
  // フォームボタン押下時に表示の変更とメール送信を行うイベント
  $button.addEventListener('click', formView.setAction);
  $backLink.addEventListener('click', goBackPage);

  // フォーム入力項目のバリデーションイベント
  $formElements.forEach(elm => {
    const current = formItems[elm.id];

    // 通常のバリデーションチェック
    formItems[elm.id].validity.init();

    // メールアドレス一致チェック用項目の設定
    const verifyItem = formItems[elm.dataset.sameValueId];

    elm.addEventListener('input', formView.createCheckAllValidation(verifyItem));
    elm.addEventListener('change', formView.createChangeStyle(current, verifyItem));
    elm.addEventListener('blur', formView.createChangeStyle(current, verifyItem));
  });
}

function goBackPage() {
  history.back();
}

export default initFormEvent;
