import initMenuEvent from './initMenuEvent';
import initFormEvent from './initFormEvent/index';
import initSplash, { hideSplash } from './initSplash';
import initTitleAnimation from './initTitleAnimation';
import initCursor from './initCursor';
import { getMedia, isStorageAvailable } from './utils';
// for pageview event
import './initFirebase';

function main() {
  const media = getMedia();
  initMenuEvent();
  initTitleAnimation(media);

  if (media === 'pc' || media === 'pc--lg') initCursor();
  if (location.pathname === '/contact/') initFormEvent();

  if(location.pathname === '/') {
    const SPLASH_STORAGE_NAME = 'disp_splash';

    if (!isStorageAvailable('sessionStorage') || sessionStorage.getItem(SPLASH_STORAGE_NAME)) {
      hideSplash();
      return;
    }

    initSplash();
    sessionStorage.setItem(SPLASH_STORAGE_NAME, 'completed');
  }
}

window.addEventListener('DOMContentLoaded', main);
