function initCursor() {
  const $stalker = document.getElementById('js-cursor-stalker');

  // CSS transform 中かどうかの判別フラグ
  let isTransforming = false;

  document.addEventListener('mouseover', () => {
    if (!$stalker.dataset.move) $stalker.dataset.move = 'true';
  })

  document.addEventListener('mousemove', function (e) {
    if (isTransforming) return;

    isTransforming = true;
    $stalker.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

    setTimeout(() => {
      isTransforming = false;
    }, 100);
  });

  const $targets = document.querySelectorAll('a, button, label, input, textarea');
  $targets.forEach($target => {
    $target.addEventListener('mouseover', function (e) {
      $stalker.setAttribute('data-hover', 'true');
    });

    $target.addEventListener('mouseout', function (e) {
      $stalker.removeAttribute('data-hover');
    });

    if ($target.tagName !== 'INPUT' && $target.tagName !== 'TEXTAREA') return;

    $target.addEventListener('focus', function (e) {
      $stalker.setAttribute('data-focused', 'true');
    });

    $target.addEventListener('blur', function (e) {
      $stalker.removeAttribute('data-focused');
    });
  });
}

export default initCursor;
