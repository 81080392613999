/**
 * @classdesc フォームアイテムの入力バリデーションを行うクラス
 * @param {HTMLInputElement|HTMLTextAreaElement} element - フォームアイテムの element 要素
 */
class FormItemValidator {
  constructor(element) {
    this.element = element;
    this.value = element.value;
    this.required = element.getAttribute('required') || element.required;
    this.readonly = element.getAttribute('readonly') || element.readonly;
    this.disabled = element.getAttribute('disabled') || element.disabled;
    this.pattern = element.dataset.pattern;
    this.valid = false;

    // Handlers
    this.validate = this.validate.bind(this);
  }

  init() {
    if (!this.shouldBeValidated()) {
      this.valid = true;
      return;
    };

    this.element.addEventListener('input', this.validate);
  }

  reset() {
    this.valid = false;
    this.value = '';
    this.element.value = '';
  }

  shouldBeValidated() {
    return (
      !this.readonly &&
      !this.disabled &&
      (this.pattern || this.required)
    );
  }

  validate(e) {
    const { value, validity } = e.currentTarget;

    this.value = value;
    this.valid = validity.valid && this.isFilledOut(value) && this.isPatternMatched(value);
  }

  isFilledOut(value) {
    return !this.required || !!(value);
  }

  isPatternMatched(value) {
    const { pattern } = this;

    return !pattern || !value || new RegExp(pattern).test(value);
  }
}

export default FormItemValidator;
