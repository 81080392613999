import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';

const config = {
  apiKey: "AIzaSyDCccG5jdineZy03xy7mNFfRkhjNzQZo14",
  projectId: "aima-info",
  storageBucket: "aima-info.appspot.com",
  messagingSenderId: "456819044216",
  appId: "1:456819044216:web:0e4d0f3af88d884679628d",
  measurementId: "G-JGCCD2EGER"
};

firebase.initializeApp(config);

export const functions = firebase.app().functions('asia-northeast1');

// usase:
// - `page_view` は自動的にやってくれる
// - analytics.logEvent('NAME', something);
// see also:
// - https://firebase.google.com/docs/analytics/events?hl=ja
// - https://developers.google.com/gtagjs/reference/event?hl=ja
export const analytics = firebase.analytics();
