// aria-, data- の attribute はブラウザ影響を少なくするため true/false ではなく付けはずしを行う
export function toggleAttribute(elm, attr, shouldBeSet) {
  if (shouldBeSet) {
    elm.setAttribute(attr, true);
  } else {
    elm.removeAttribute(attr);
  }
}

export function isStorageAvailable(type) {
  let storage;
  try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  }
  catch(e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          (storage && storage.length !== 0);
  }
}

export function getMedia() {
  if (window.matchMedia( "(min-width: 1388px)" ).matches) {
    return 'pc--lg';
  } else if (window.matchMedia( "(min-width: 1024px)" ).matches) {
    return 'pc';
  } else if (window.matchMedia( "(min-width: 768px)" ).matches) {
    return 'tablet';
  }

  return 'sp';
}
